import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import { MdContentCopy } from "react-icons/md";
import { GrNotes } from "react-icons/gr";
import { FaMicrophone, FaRegStopCircle } from "react-icons/fa";

// const API_BASE_URL = 'http://127.0.0.1:5000';
const API_BASE_URL = 'https://scribe-backend.onrender.com';

function App() {
  const [recording, setRecording] = useState(false);
  const [audioURL, setAudioURL] = useState('');
  const [transcription, setTranscription] = useState('');
  const [summarizedNotes, setSummarizedNotes] = useState('');
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [timer, setTimer] = useState(0);
  const [timerInterval, setTimerInterval] = useState(null);
  const [isLoading, setIsLoading] = useState(false); 

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          const recorder = new MediaRecorder(stream);
          setMediaRecorder(recorder);

          const audioChunks = [];
          recorder.ondataavailable = event => {
            audioChunks.push(event.data);
          };

          recorder.onstop = async () => {
            const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioURL(audioUrl);
            await sendAudioToServer(audioBlob); // Call the function to send audio to server
          };
        })
        .catch(err => console.error('Error accessing the microphone', err));
    }
  }, []);

  const startRecording = () => {
    if (mediaRecorder) {
      setRecording(true);
      mediaRecorder.start();
      startTimer();
      setTranscription('');
      setSummarizedNotes('');
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      setRecording(false);
      mediaRecorder.stop();
      stopTimer();
    }
  };

  const sendAudioToServer = async (audioBlob) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', audioBlob, 'audio.wav');

    try {
      const response = await axios.post(API_BASE_URL+'/transcribe', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const responseData = response.data
      setTranscription(responseData.transcript);
      setSummarizedNotes(responseData.summary);
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const startTimer = () => {
    setTimer(0);
    const interval = setInterval(() => {
      setTimer((prevTime) => prevTime + 1);
    }, 1000);
    setTimerInterval(interval);
  };

  const stopTimer = () => {
    if (timerInterval) {
      clearInterval(timerInterval);
      setTimerInterval(null);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(summarizedNotes).then(() => {
      console.log('Text copied to clipboard successfully!');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };  

  return (
    <div className="App">
      <header className="App-header">
        <p><GrNotes /> Best Scribe AI</p>
      </header>
      <div>
        <button className="button" style={{ marginRight: '10px' }} onClick={startRecording} disabled={recording}><FaMicrophone /> Start Recording</button>
        <button className="button" onClick={stopRecording} disabled={!recording}><FaRegStopCircle /> Stop Recording</button>
        <br />
        {recording && <div><p className="recordingIndicator">●</p> Recording {formatTime(timer)}</div>}
        <br />
        {audioURL && <audio src={audioURL} controls />}
        {isLoading && <div><p>Transcribing Conversation with AI...</p></div>} 
        {summarizedNotes && (
          <div className="transcription-container">
            <h4><GrNotes /> SOAP Notes:</h4>
            <p contentEditable="true" onBlur={e => setSummarizedNotes(e.target.innerText)}>{summarizedNotes}</p>
            <br />
            <button onClick={copyToClipboard}><MdContentCopy/> Copy Text</button>
          </div>
        )}
        {transcription && (
          <div className="transcription-container">
            <h4>Raw Transcript:</h4>
            <p>{transcription}</p>
          </div>
        )}
      </div>
    </div>
  );  
}

export default App;
